<template>
	<div class="user-page__wrapper">
		<div class="user-page">
			<UserMenu :edit-mode="editMode" />
			<div class="user-page__content user-edit-profile" :class="{ 'has-notification': successNotification }">
				<h2>My Profile</h2>

				<form @submit.prevent="updateProfile">
					<div :class="{ 'columns user-page__qr-wrapper': user.qrCodeUrl }">
						<div :class="{ 'column is-two-thirds': user.qrCodeUrl }">
							<div class="columns">
								<div class="column">
									<div class="field">
										<label class="label">First Name *</label>
										<div class="control">
											<input v-model="user.firstName" class="input" type="text" placeholder="First Name" :disabled="!editMode" :class="{ 'is-danger': inputErrors['firstName'] }" />
										</div>
									</div>
								</div>
								<div class="column">
									<div class="field">
										<label class="label">Last Name *</label>
										<div class="control">
											<input v-model="user.lastName" class="input" type="text" placeholder="Last Name" :disabled="!editMode" :class="{ 'is-danger': inputErrors['lastName'] }" />
										</div>
									</div>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<div class="field">
										<label class="label">Job Title *</label>
										<div class="control">
											<input v-model="user.job_title" class="input" type="text" placeholder="Job Title" :disabled="!editMode" :class="{ 'is-danger': inputErrors['job_title'] }" />
										</div>
									</div>
								</div>
								<div class="column">
									<div class="field">
										<label class="label">Company/Institution</label>
										<div class="control">
											<input v-model="user.company" class="input" type="text" placeholder="Company/Institution" :disabled="!editMode" />
										</div>
									</div>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<div class="field">
										<label class="label">Country *</label>
										<div class="control">
											<v-select
												v-model="user.user_country"
												:options="countries"
												label="name"
												:clearable="false"
												:disabled="!editMode"
												:class="{ 'is-danger': inputErrors['user_country'] }"
											></v-select>
										</div>
									</div>
								</div>
								<div class="column">
									<div class="field">
										<label class="label">E-mail</label>
										<div class="control">
											<input v-model="user.email" class="input" type="text" placeholder="E-mail" disabled />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="user.qrCodeUrl" class="column is-one-third user-page__qr">
							<div @click="qrPopup">
								<img :src="user.qrCodeUrl" />
							</div>
							<a :href="user.qrCodePdfUrl" target="_blank"><strong>On-site badge printing</strong><br />Download in PDF</a>
						</div>
					</div>

					<div class="columns">
						<div class="column">
							<div class="field">
								<label class="label">Speciality *</label>
								<div class="control">
									<v-select
										v-model="user.speciality"
										:options="specialities"
										label="name"
										:clearable="false"
										:disabled="!editMode"
										:class="{ 'is-danger': inputErrors['speciality'] }"
									></v-select>
								</div>
							</div>
						</div>
						<div class="column">
							<div class="field">
								<label class="label">Profession *</label>
								<div class="control">
									<v-select
										v-model="user.user_profession"
										:options="professions"
										label="name"
										:clearable="false"
										:disabled="!editMode"
										:class="{ 'is-danger': inputErrors['user_profession'] }"
									></v-select>
								</div>
							</div>
						</div>
						<div class="column">
							<div class="field">
								<label class="label">Working Place *</label>
								<div class="control">
									<v-select
										v-model="user.organization_type"
										:options="organizations"
										label="name"
										:clearable="false"
										:disabled="!editMode"
										:class="{ 'is-danger': inputErrors['organization_type'] }"
									></v-select>
								</div>
							</div>
						</div>
					</div>
					<div class="columns">
						<div class="column">
							<div class="field">
								<label class="label">EAACI Section *</label>
								<div class="control">
									<v-select
										v-model="user.preferredSection"
										:options="sections"
										label="name"
										:clearable="false"
										:disabled="!editMode"
										:class="{ 'is-danger': inputErrors['preferredSection'] }"
									></v-select>
								</div>
							</div>
						</div>
						<div class="column">
							<div class="field">
								<label class="label">EAACI Interest Groups *</label>
								<div class="control">
									<v-select
										v-model="user.eaaci_interest_groups"
										multiple
										:options="interestGroups"
										label="name"
										:disabled="!editMode"
										:class="{ 'is-danger': inputErrors['eaaci_interest_groups'] }"
									></v-select>
								</div>
							</div>
						</div>
						<div class="column">
							<div class="field">
								<label class="label">EAACI Working Groups *</label>
								<div class="control">
									<v-select
										v-model="user.eaaci_working_groups"
										multiple
										:options="workingGroups"
										label="name"
										:disabled="!editMode"
										:class="{ 'is-danger': inputErrors['eaaci_working_groups'] }"
									></v-select>
								</div>
							</div>
						</div>
					</div>
					<div class="field field-switch">
						<strong>Do you have a license to prescribe a medicine?</strong>
						<input id="prescriber" v-model="user.prescriber" type="checkbox" name="prescriber" class="switch is-rounded" :disabled="!editMode" />
						<label for="prescriber">{{ user.prescriber ? 'Yes' : 'No' }}</label>
					</div>
					<div class="field field-switch">
						<strong>Are you employed by a pharmaceutical company?</strong>
						<input id="pharmaEmployee" v-model="user.pharmaEmployee" type="checkbox" name="pharmaEmployee" class="switch is-rounded" :disabled="!editMode" />
						<label for="pharmaEmployee">{{ user.pharmaEmployee ? 'Yes' : 'No' }}</label>
					</div>
					<div class="field">
						<label class="label">Bio</label>
						<div class="control">
							<textarea v-model="user.bio" class="textarea" placeholder="Your bio" :disabled="!editMode"></textarea>
						</div>
					</div>

					<label class="label">My Social Networks</label>
					<div class="user-edit-socials">
						<div class="control">
							<span><inline-svg :src="require('../assets/linkedin.svg')" width="25"></inline-svg></span>
							<input v-model="socialLinkedin" class="input" type="text" placeholder="Please provide the link of your Linkedin account..." :disabled="!editMode" />
						</div>
						<div class="control">
							<span><inline-svg :src="require('../assets/twitter.svg')" width="25"></inline-svg></span>
							<input v-model="socialTwitter" class="input" type="text" placeholder="Please provide the link of your Twitter account..." :disabled="!editMode" />
						</div>
						<div class="control">
							<span><inline-svg :src="require('../assets/facebook.svg')" width="25"></inline-svg></span>
							<input v-model="socialFacebook" class="input" type="text" placeholder="Please provide the link of your Facebook account..." :disabled="!editMode" />
						</div>
						<div class="control">
							<span><inline-svg :src="require('../assets/instagram.svg')" width="25"></inline-svg></span>
							<input v-model="socialInstagram" class="input" type="text" placeholder="Please provide the link of your Instagram account..." :disabled="!editMode" />
						</div>
					</div>

					<hr />

					<label class="label">Profile Visibility</label>
					<p>Control if your profile is show in the attendees list.</p>

					<div class="field">
						<input id="publicProfile" v-model="user.public_profile" type="checkbox" name="publicProfile" class="switch is-rounded" :disabled="!editMode" />
						<label for="publicProfile">Public</label>
					</div>

					<p>To establish connections, send messages and have access to the attendees list, your profile must be set to public.</p>

					<p>Your email address ({{ user.email }}) will only be visible when a connection is established.</p>

					<label class="checkbox-wrapper">
						<span class="input-wrapper">
							<input v-model="user.profileChecked" type="checkbox" />
						</span>
						<span>
							I hereby confirm that the above details are accurate and true to my knowledge.
						</span>
					</label>

					<div class="has-text-centered">
						<a v-if="!editMode" class="button is-primary" @click.prevent="startEdit">Edit details</a>
						<button v-if="editMode" class="button is-primary" type="submit" :disabled="!user.profileChecked || !isValid">Save details</button>
					</div>

					<div class="user-password has-text-grey-light">
						<label class="label has-text-grey-light">Password</label>
						<p>
							To change your password please visit the <a href="https://my.eaaci.org/" target="_blank">MyEAACI Portal</a>. Please be aware this will affect your access details in
							all EAACI's digital platforms.
						</p>
					</div>
				</form>
				<div v-if="successNotification" class="user-edit-profile__success">
					<span class="icon">
						<inline-svg :src="require('../assets/check.svg')" width="25"></inline-svg>
					</span>
					<span>Your details have been saved</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import UserMenu from '../components/UserMenu';
	import $http from '../utils/http';
	import session from '../utils/session';
	import QRModal from '@/components/QRModal';

	const availableSocials = { linkedin: 'socialLinkedin', twitter: 'socialTwitter', facebook: 'socialFacebook', instagram: 'socialInstagram' };

	export default {
		name: 'EditProfile',
		components: { UserMenu, QRModal },
		data() {
			return {
				user: {},

				editMode: true,
				image: null,
				successNotification: false,

				socialNetworks: [],
				socialLinkedin: null,
				socialTwitter: null,
				socialFacebook: null,
				socialInstagram: null,

				session: session.state,

				countries: [],
				organizations: [],
				sections: [],
				professions: [],
				specialities: [],
				interestGroups: [],
				workingGroups: [],
				agree: false,
			};
		},
		computed: {
			isValid() {
				if (
					this.user.firstName &&
					this.user.lastName &&
					this.user.job_title &&
					this.user.user_country &&
					this.user.speciality &&
					this.user.user_profession &&
					this.user.organization_type &&
					this.user.preferredSection &&
					this.user.eaaci_interest_groups &&
					this.user.eaaci_interest_groups.length > 0 &&
					this.user.eaaci_working_groups &&
					this.user.eaaci_working_groups.length > 0
				)
					return true;
				return false;
			},
			inputErrors() {
				let errors = {};
				if (!this.user.firstName) errors['firstName'] = true;
				if (!this.user.lastName) errors['lastName'] = true;
				if (!this.user.job_title) errors['job_title'] = true;
				if (!this.user.user_country) errors['user_country'] = true;
				if (!this.user.speciality) errors['speciality'] = true;
				if (!this.user.user_profession) errors['user_profession'] = true;
				if (!this.user.organization_type) errors['organization_type'] = true;
				if (!this.user.preferredSection) errors['preferredSection'] = true;
				if (!this.user.eaaci_interest_groups || this.user.eaaci_interest_groups.length === 0) errors['eaaci_interest_groups'] = true;
				if (!this.user.eaaci_working_groups || this.user.eaaci_working_groups.length === 0) errors['eaaci_working_groups'] = true;
				return errors;
			},
		},
		beforeRouteEnter(to, from, next) {
			$http
				.all([
					$http.get(`user-info`),
					$http.get(`countries?_limit=300`),
					$http.get(`user-organization-types`),
					$http.get(`user-preferred-sections`),
					$http.get(`user-professions`),
					$http.get(`user-specialities`),
					$http.get(`eaaci-interest-groups`),
					$http.get(`eaaci-working-groups`),
				])
				.then(
					$http.spread((data, countries, organizations, sections, professions, specialities, interestGroups, workingGroups) => {
						next(vm => {
							vm.setData(data);
							vm.setSelects(countries, organizations, sections, professions, specialities, interestGroups, workingGroups);
						});
					})
				);
		},
		beforeRouteUpdate(to, from, next) {
			$http
				.all([
					$http.get(`user-info`),
					$http.get(`countries?_limit=300`),
					$http.get(`user-organization-types`),
					$http.get(`user-preferred-sections`),
					$http.get(`user-professions`),
					$http.get(`user-specialities`),
					$http.get(`eaaci-interest-groups`),
					$http.get(`eaaci-working-groups`),
				])
				.then(
					$http.spread((data, countries, organizations, sections, professions, specialities, interestGroups, workingGroups) => {
						this.setData(data);
						this.setSelects(countries, organizations, sections, professions, specialities, interestGroups, workingGroups);
						next();
					})
				);
		},
		beforeRouteLeave(to, from, next) {
			if (this.session.loggedIn === true && this.session.user && !this.session.user.profileChecked) {
				window.alert('Please confirm your account by saving your details before continuing.');
			} else {
				next();
			}
		},
		methods: {
			setData({ data }) {
				this.user = data;

				this.image = data.image;
				this.socialNetworks = data.social_networks;

				if (this.socialNetworks) {
					for (let soc in availableSocials) {
						let social = this.socialNetworks.find(e => e.type === soc);
						if (social) {
							this.$data[availableSocials[soc]] = social.url;
						}
					}
				}
			},
			setSelects(countries, organizations, sections, professions, specialities, interestGroups, workingGroups) {
				this.countries = countries.data;
				this.organizations = organizations.data;
				this.sections = sections.data;
				this.professions = professions.data;
				this.specialities = specialities.data;
				this.interestGroups = interestGroups.data;
				this.workingGroups = workingGroups.data;
			},
			updateProfile() {
				let socials = [];

				for (let soc of Object.values(availableSocials)) {
					if (this.$data[soc]) {
						let socialName = Object.keys(availableSocials).find(key => availableSocials[key] === soc);
						let social = this.socialNetworks.find(e => e.type === socialName);
						if (social) {
							socials.push({ ...social, url: this.$data[soc] });
						} else {
							socials.push({ type: socialName, url: this.$data[soc] });
						}
					}
				}

				let sessionData = {
					profile: true,
					firstName: this.user.firstName,
					lastName: this.user.lastName,
					job_title: this.user.job_title,
					company: this.user.company,
					user_country: this.user.user_country,
					speciality: this.user.speciality,
					user_profession: this.user.user_profession,
					organization_type: this.user.organization_type,
					preferredSection: this.user.preferredSection,
					eaaci_interest_groups: this.user.eaaci_interest_groups,
					eaaci_working_groups: this.user.eaaci_working_groups,
					prescriber: this.user.prescriber,
					pharmaEmployee: this.user.pharmaEmployee,
					bio: this.user.bio,
					public_profile: this.user.public_profile,
					social_networks: socials,
					profileChecked: true,
				};

				let data = {
					...sessionData,
				};

				data.eaaci_interest_groups = data.eaaci_interest_groups.map(e => e.id);
				data.eaaci_working_groups = data.eaaci_working_groups.map(e => e.id);
				data.user_country = data.user_country?.id || null;
				data.speciality = data.speciality?.id || null;
				data.user_profession = data.user_profession?.id || null;
				data.organization_type = data.organization_type?.id || null;
				data.preferredSection = data.preferredSection?.id || null;

				$http.put(`/user-info`, data).then(d => {
					session.updateSession(sessionData);
					this.successNotification = true;
					window.scroll({
						top: 0,
						behavior: 'smooth',
					});
					window.setTimeout(() => {
						this.successNotification = false;
					}, 5000);
				});
			},
			startEdit() {
				this.editMode = true;
			},
			qrPopup() {
				this.$vfm.show({
					component: QRModal,
					bind: {
						qrCodeUrl: this.user.qrCodeUrl,
					},
				});
			},
		},
	};
</script>
